import React, { useEffect, useState } from 'react';
import back from '../images/back-left.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Calendar } from 'primereact/calendar';
import SidebarMenu from '../components/SidebarMenu';
import { Button } from 'primereact/button';
import rightCircle from '../images/right-circle.svg';
import leftCircle from '../images/left-circle.svg';
import axios from 'axios';
import constants from '../constants/constants';
import { useStoreState } from 'easy-peasy';

function UserBooked() {
    const navigate = useNavigate();
    const [date, setDate] = useState(null);

    const userDetail = useStoreState((state) => state.tabModel.userDetail);
    const bookingResponse = useStoreState((state) => state.tabModel.bookingResponse);
    // console.log(bookingResponse);
    
    const originalDate = new Date(bookingResponse?.slotDate);
    const options = { month: "short", day: "numeric" };
    
    const formattedDate = originalDate?.toLocaleString("en-US", options);
    // console.log(formattedDate);
    const originalDates = new Date(bookingResponse?.clubSlot?.slotStartTimeStamp);
    const startTime = originalDates?.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
     // console.log(startTime);
  

    return (
        <div className='ub-admin-wrapper'>
            <div className='flex justify-content-between align-items-center ds-admin-topbar'>
                <Link to='/user-home' className='no-underline flex w-6'>
                    <img src={back} className='mr-2' alt='arrow-back' />
                    <h2 className='bk-heading'>Booked!</h2>
                </Link>
                <SidebarMenu />
            </div>
            <div className='bk-inner'>
                <div className=''>
                    <div className='card bh-card-inner p-3'>
                        <div className='os-card-inner text-center'>
                            Bhubaneswar Golf Club
                        </div>
                        <div className='oss-line my-3'></div>
                        <div>
                            <h6 className='bk-title mb-2'>Date & Time</h6>
                            <p className='bk-value'>{formattedDate+','+startTime}</p>
                        </div>
                        <div className='oss-line my-3'></div>
                        <div className='mb-4'>
                            <h6 className='bk-title mb-3'>Name of Players</h6>

                            <div className="grid">
                                <div className="col-6 bk-value">
                                    {bookingResponse?.player?.split(',')?.filter(name => name.trim() !== 'undefined')?.join(', ')}
                                </div>
                            </div>
                        </div>
                        <div className='oss-line mt-3 mb-4'></div>
                        <div className='flex justify-content-center align-items-center relative mb-2'>
                            <img src={leftCircle} className='absolute left-0' />
                            <div className='ub-outer-layer'>
                                <div className='ub-ball'>
                                    <h4 className='ub-ball-title mb-1'>Name</h4>
                                    <h1 className='ub-ball-name'>{userDetail?.userFname}</h1>
                                    <div className='ub-line'></div>
                                    <h4 className='ub-ball-title mb-1'>Booking Id</h4>
                                    <h1 className='ub-ball-name'>{bookingResponse?.bookingId}</h1>
                                    <div className='ub-dot'></div>
                                </div>
                            </div>
                            <img src={rightCircle} className='absolute right-0' />
                        </div>
                    </div>
                </div>
                <Link to='/user-home' className='no-underline w-6'>
                    <div className="my-3 us-bottom-btn">
                        <Button label="Home" id="large-btn" />
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default UserBooked;